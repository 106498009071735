<template>
    <div class="body-container">
        <BannerSection title="Prestamos con garantia de auto en Querétaro" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        En Querétaro, como en muchas otras partes de México y el mundo, enfrentar imprevistos o aprovechar oportunidades a veces requiere de soluciones financieras ágiles y convenientes.
                    </p>
                    <p>
                        Tener acceso a opciones de financiamiento accesibles puede hacer una diferencia significativa, especialmente cuando se presentan situaciones que exigen una solución inmediata.
                    </p>
                    <p>
                        Por esto, si te encuentras en esta parte del país, debes conocer las alternativas de <b>préstamos en Querétaro</b>, entre ellas las que involucran garantía de auto. Una opción que proporciona el soporte necesario para mantener o mejorar la calidad de vida sin comprometer la estabilidad económica.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cuándo considerar un préstamo con garantía de auto en Querétaro?
                        </h2>
                    </span>

                    <p>
                        Estos son algunos escenarios donde este tipo de préstamo puede ser especialmente útil:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p>
                                Emergencias médicas: si te enfrentas a gastos médicos no cubiertos o inesperados, un préstamo rápido puede proporcionar los fondos necesarios para atender la salud sin demoras.
                            </p>
                        </li>
                        <li>
                            <p>
                                Reparaciones urgentes en el hogar: después de incidentes como daños por tormentas o averías importantes que requieren atención inmediata, el acceso a financiamiento rápido te ayuda a restablecer tu hogar a la normalidad.
                            </p>
                        </li>
                        <li>
                            <p>
                                Oportunidades de inversión: cuando surgen oportunidades de invertir en un proyecto prometedor o adquirir activos valiosos a precios ventajosos, contar con liquidez inmediata puede ser decisivo.
                            </p>
                        </li>
                    </ul>
                    <br>
                    <p>
                        Los <b>préstamos en Querétaro</b> con garantía de auto como los que ofrece Ibancar son accesibles y ofrecen ventajas inmediatas como procesos de aprobación rápidos y la posibilidad de seguir utilizando el auto mientras se amortiza el préstamo.
                    </p>
                    <br>
                </div>
            </div>
        </div>


        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative mt-5  pb-3 pb-md-0 ">
                <div class="text-container">
                        <span class="bold-text">
                            <h2 style="font-size: 20px;">¿Cómo funcionan los préstamos con garantía de auto de Ibancar?</h2>
                        </span>
                        <p>
                            Obtener un préstamo con Ibancar usando tu auto como garantía es un proceso sencillo y rápido que te permite seguir con tu ritmo de vida sin interrupciones. Te explicamos cómo puedes acceder a este tipo de financiamiento:

                        </p>
                        <br>
                        <ol>
                            <li>
                                <p>
                                    Solicitud en línea: todo comienza con un formulario simple que puedes completar desde cualquier lugar, sin necesidad de visitar una sucursal. Solo necesitas acceso a internet y los documentos de tu vehículo a mano.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Evaluación rápida: una vez enviada tu solicitud, Ibancar evalúa la información de tu auto para pre aprobar el préstamo. Este proceso es rápido, de modo que no tendrás que esperar días para obtener una respuesta.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Desembolso del préstamo: tras la aprobación, los fondos se transfieren directamente a tu cuenta bancaria. Esto ocurre generalmente en menos de 24 horas.
                                </p>
                            </li>
                            <br>
                        </ol>
                        <br>
                        <p>
                            Una de las grandes ventajas de este tipo de financiamiento es que, a diferencia de los tradicionales <b>empeños en Querétaro</b>, con Ibancar no necesitas dejar tu vehículo ni cambiar la titularidad del mismo. Puedes seguir usándolo como siempre mientras pagas el préstamo.
                        </p>
                        <p>
                            Como ves, Ibancar ofrece una <b>opción robusta y confiable para quienes requieren soluciones financieras urgentes en Querétaro</b>. Al proporcionar préstamos con garantía de auto, facilita el acceso a fondos rápidos sin los inconvenientes de los métodos tradicionales de empeño. 
                        </p>
                        <p>
                            Si te encuentras en una situación que requiere liquidez inmediata, no dudes en contactarnos. Comienza el proceso de solicitud y descubre cómo puedes resolver tus necesidades financieras de manera eficaz y segura.
                        </p>
                        <br>
                        <br>
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosDeDineroRapido',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Prestamos con garantia de auto en Querétaro',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: '¿Necesitas liquidez rápida? Descubre los préstamos con garantía de auto en Querétaro. Obtén tu dinero en menos de 24 horas y sigue usando tu vehículo. Sin dejar tu auto y con trámites 100% en línea. ¡Fácil, seguro y confiable!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos en Querétaro - empeños en queretaro'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-garantia-auto-queretaro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>